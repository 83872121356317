import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Footer from './Footer/Footer';
import Content from './Content/Content';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Justin D Whitaker</title>
    </Helmet>
    <div>
      <Content />
      <Footer />
    </div>
  </React.StrictMode>
);
