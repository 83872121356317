import './HeadlineRegion.css';
import sig from './res/sig.png';

function HeadlineRegion() {
  return (
    <div className="HeadlineRegion">
      <div className="CallToAction">
        <div>
          <img className="Signature" src={sig} alt="Signature"></img>
          <p style={{"text-align":"center"}}><a href="mailto:justin@whitaker.pro">justin@whitaker.pro</a></p>
        </div>
      </div>
    </div>
  );
}

export default HeadlineRegion;
