import "./Delineator.css";

function Delineator() {
    return (
      <div>
        <hr className="Delineator" />
      </div>
    );
  }
  
  export default Delineator;
