import './Content.css';
import BodyRegion from "./BodyRegion/BodyRegion"
import HeadlineRegion from "./HeadlineRegion/HeadlineRegion";
import PhotoRegion from "./PhotoRegion/PhotoRegion";
import background from './banner-white.png';

function Content() {
    return (
        <div className="Content" style={{ backgroundImage:  `url(${background})` }} >
            <HeadlineRegion />
            <PhotoRegion />
            <BodyRegion />
        </div>
    );
}

export default Content;