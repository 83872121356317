import './PhotoRegion.css';
import Photo from './Photo/Photo';

function PhotoRegion() {
  return (
    <div className="PhotoRegion">
        <Photo />
    </div>
  );
}

export default PhotoRegion;
