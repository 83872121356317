import './Footer.css';
import { SocialIcon } from 'react-social-icons'

function Footer() {
  return (
    <div className="Footer">
        <div className="SocialsRow">
          <SocialIcon className="Socials-Icon" fgColor="white" bgColor="#0000" url="https://twitter.com/_justinwhitaker" style={{ height: 40, width: 40 }} target="_blank"/>
          <SocialIcon className="Socials-Icon" fgColor="white" bgColor="#0000" url="https://www.linkedin.com/in/justin-d-whitaker" style={{ height: 40, width: 40 }} target="_blank"/>
          <SocialIcon className="Socials-Icon" fgColor="white" bgColor="#0000" url="mailto:justin@whitaker.pro" style={{ height: 40, width: 40 }} target="_blank"/>
          <SocialIcon className="Socials-Icon" fgColor="white" bgColor="#0000" url="https://www.youtube.com/@justindwhitaker" style={{ height: 40, width: 40 }} target="_blank"/>
          <SocialIcon className="Socials-Icon" fgColor="white" bgColor="#0000" url="https://github.com/jdwhitaker" style={{ height: 40, width: 40 }} target="_blank"/>
        </div>
    </div>
  );
}

export default Footer;