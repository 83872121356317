import './Certs.css';
import Cert from './Cert/Cert';

function Certs() {
  return (
    <div className="Container">
      <h1 className="CertsHeader">Certifications</h1>
      <div className="CertRow">
        { Cert('gsom.png', 'https://www.credly.com/badges/fd18f748-2568-4ff3-9554-3fb38d0a78f1/public_url') }
        { Cert('gstrt.png', 'https://www.credly.com/badges/24f10ab2-67ee-4093-a6e6-30715d38c4f6/public_url') }
        { Cert('gcpm.png', 'https://www.credly.com/badges/58a5dc70-91d5-4879-b3d4-64514796f5b7/public_url') }
      </div>
      <div className="CertRow">
        { Cert('gcda.png', 'https://www.credly.com/badges/fe235518-de9d-4adf-a9cc-9b5e7ab9dfd9/public_url') }
        { Cert('gdsa.png', 'https://www.credly.com/badges/4f3b2ede-bdfb-4cb6-a210-0e538b9f6e13/public_url') }
        { Cert('gcia.png', 'https://www.credly.com/badges/e8b787c2-f3a5-4091-8099-9ad74ec64401/public_url') }
      </div>
      <div className="CertRow">
        { Cert('gsec.png', 'https://www.credly.com/badges/3449701c-dcb5-4845-8b9c-6db6b6d92feb/public_url') }
        { Cert('gcih.png', 'https://www.credly.com/badges/76d432bb-6d69-4c5b-b172-4641b8bcf853/public_url') }
        { Cert('gweb.png', 'https://www.credly.com/badges/f4488b73-da7a-44db-9842-35c474a7ebd8/public_url') }
      </div>
      <div className="CertRow">
        { Cert('giac-ab.png', 'https://www.credly.com/badges/4a2cbe63-6d36-44b9-8bad-5579aca98ba5/public_url') } 
        { Cert('cissp.png', 'https://www.credly.com/badges/6d64bcde-ae56-4d04-81ba-e8d2f97f8cde/public_url') } 
        { Cert('ccsp.png', 'https://www.credly.com/badges/1989b931-baf2-4c6e-8a0c-aba5406742e3/public_url') } 
      </div>
      <div className="CertRow">
        { Cert('aws-css.png', 'https://www.credly.com/badges/d90195c4-2a23-4882-a0fe-2e675a8b1418/public_url') } 
        { Cert('aws-csaa.png', 'https://www.credly.com/badges/0226411a-1567-43ac-a3d9-ede88ff4fc62/public_url') } 
        { Cert('aws-ccp.png', 'https://www.credly.com/badges/624e654a-c423-466d-8911-737383bb250d/public_url') }
      </div>
      <div className="CertRow">
        <div className="FillerCert"></div>
        { Cert('oscp.png', 'https://www.credly.com/badges/518a6d00-4e9e-4f16-b511-d0af8cb2b9ec/public_url') }
        <div className="FillerCert"></div>
      </div>
    </div>
  );
}

export default Certs;
