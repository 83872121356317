import './BodyRegion.css';
import Certs from "./Certs/Certs";
import Delineator from './Delineator/Delineator';
import Bio from './Bio/Bio';
import Publications from './Publications/Publications';

function BodyRegion() {
    return (
        <div className="BodyRegion">
            <Bio />
            <Delineator />
            <Publications />
            <Delineator />
            <Certs />
        </div>
    );
}

export default BodyRegion;