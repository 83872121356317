import './Cert.css';

function Cert(imgPath, href){
    const img = require('../res/' + imgPath);
    return (
        <a className='Cert' href={href} target='_blank'>
            <img style={{width: "100%"}} src={img}></img>
        </a>
    );
}

export default Cert;