import './Bio.css';

function Bio() {
  return (
    <div className="Bio">
      <p>
        I am a technical cybersecurity advisor. My mission is to apply my expertise to secure the software on which our society and its enterprises depend.
      </p>
      <p>
        I work with Cisco as a security architect.
        I graduated <i>summa cum laude</i> with a B.S. in Computer Science from East Carolina University, and I have a M.S. in Information Security Engineering from the SANS Technology Institute.
        I am a cybersecurity generalist, but I specialize in its intersections with AI.
      </p>
    </div>
  );
}

export default Bio;
