import './Publications.css';

function Publications() {
  return (
    <div className="Publications">
      <div>
        <h1 className="PublicationsHeader">Publications</h1>
        <p>
          Santos, O., Whitaker, J., Groetzinger, J. (2024).&nbsp;
          <a target="_blank" href="https://sec.cloudapps.cisco.com/security/center/resources/selecting-embedding-models">
            Selecting Embedding Models.
          </a>
          &nbsp;<i>Cisco Systems, Inc.</i>
        </p>
        <p>
          Whitaker, J. (2023).&nbsp;
          <a target="_blank" href="https://www.sans.edu/cyber-research/hybrid-vigor-improving-detection-combining-ml-extracted-iocs/">
            Hybrid Vigor: Improving Detection by Combining ML with Extracted IOCs.
          </a>
          &nbsp;<i>The SANS Technology Institute.</i>
        </p>
        <p>
          Whitaker, J., Prasad, S., Reaves, B., & Enck, W. (2019).&nbsp;
          <a target="_blank" href="https://dl.acm.org/doi/10.1145/3338500.3360332">
            Thou Shalt Discuss Security: Quantifying the Impacts of Instructions to RFC Authors.
          </a>
          &nbsp;<i>Proceedings of the 5th ACM Workshop on Security Standardisation Research Workshop.</i>
        </p>
        <p>
          Andow, B., Mahmud, S.Y., Whitaker, J., Enck, W., Reaves, B., Singh, K., & Egelman, S. (2020).&nbsp;
          <a target="_blank" href="https://www.usenix.org/system/files/sec20-andow.pdf">
            Actions Speak Louder than Words: Entity-Sensitive Privacy Policy and Data Flow Analysis with PoliCheck.
          </a>
          &nbsp;<i>USENIX Security Symposium.</i>
        </p>
        <p>
          Andow, B., Mahmud, S.Y., Wang, W., Whitaker, J., Enck, W., Reaves, B., Singh, K., & Xie, T. (2019).&nbsp;
          <a target="_blank" href="https://www.usenix.org/system/files/sec19-andow.pdf">
            PolicyLint: Investigating Internal Privacy Policy Contradictions on Google Play.
          </a>
          &nbsp;<i> USENIX Security Symposium.</i>
        </p>
      </div>
    </div>
  );
}

export default Publications;
